import { Fragment } from 'react';
import { string, object, func } from 'prop-types';
import styles from './mobileNavDrilldown.scss';
import classnames from 'classnames';
import ArrowLeft from 'dibs-icons/exports/legacy/ArrowLeft';
import { FormattedMessage } from 'dibs-react-intl';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import MobileNavDrilldownItem from '../MobileNavDrilldown/MobileNavDrilldownItem';

function MobileNavDrilldown(props) {
    const { className, onBackClick, parentSectionName, sectionToShow } = props;
    const containerClass = classnames(styles.container, className, {
        [styles.hide]: sectionToShow !== parentSectionName,
    });
    return (
        <div className={containerClass} key={parentSectionName}>
            <div
                className={styles.back}
                onClick={onBackClick}
                onKeyDown={onBackClick}
                role="button"
                tabIndex="0"
            >
                <ArrowLeft className={styles.backIcon} />
                <div className={styles.backText}>
                    <FormattedMessage id="mobile.navigation.drilldown.back" defaultMessage="Back" />
                </div>
            </div>
            {props.module.items.map(item => (
                <Fragment key={item.name}>
                    <div className={styles.title}>{item.name}</div>
                    {item.items.map(({ name, linkData, count }) => (
                        <MobileNavDrilldownItem
                            key={name}
                            sectionName={item.name}
                            parentSectionName={parentSectionName}
                            name={name}
                            linkData={linkData}
                            count={count}
                        />
                    ))}
                </Fragment>
            ))}
        </div>
    );
}

MobileNavDrilldown.propTypes = {
    className: string,
    onBackClick: func.isRequired,
    module: object.isRequired,
    parentSectionName: string.isRequired,
    sectionToShow: string.isRequired,
};

export default createFragmentContainer(MobileNavDrilldown, {
    module: graphql`
        fragment MobileNavDrilldown_module on NavigationModule {
            items {
                name
                items {
                    name
                    linkData {
                        path
                        ...SeoLink_linkData
                    }
                }
            }
        }
    `,
});
