/**
 * @generated SignedSource<<f42f6e22902d1db83cf21967fd76ec33>>
 * @relayHash 170b7fb92e6b8b9e17a9b3daa0471944
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/505.0.0-2025-04-04T08:13:11.275Z/mobileNavWrapperRootQuery

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type mobileNavWrapperRootQuery$variables = {
  fetchRegionalInfo: boolean;
  fetchTrackingGdprInfo?: boolean | null;
  fetchUserRecentHistoryItem?: boolean | null;
  hasUserId: boolean;
  isClient: boolean;
  localRecentHistoryItemIds?: ReadonlyArray<string | null> | null;
  previewKey?: string | null;
  userCountryCode?: string | null;
  userId: string;
  userIds?: ReadonlyArray<string> | null;
  variantId?: string | null;
  zipCode: string;
};
export type mobileNavWrapperRootQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"MobileNavWrapper_viewer">;
  };
};
export type mobileNavWrapperRootQuery = {
  response: mobileNavWrapperRootQuery$data;
  variables: mobileNavWrapperRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchRegionalInfo"
},
v1 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "fetchTrackingGdprInfo"
},
v2 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "fetchUserRecentHistoryItem"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isClient"
},
v5 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "localRecentHistoryItemIds"
},
v6 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "previewKey"
},
v7 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userIds"
},
v10 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "variantId"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "zipCode"
},
v12 = {
  "kind": "Variable",
  "name": "countryCode",
  "variableName": "userCountryCode"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v15 = [
  {
    "kind": "Variable",
    "name": "excludeItemPks",
    "variableName": "localRecentHistoryItemIds"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 12
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVip",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVerifiedTrade",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v20 = [
  (v19/*: any*/)
],
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "UserPreferences",
  "kind": "LinkedField",
  "name": "preferences",
  "plural": false,
  "selections": (v20/*: any*/),
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLinkable",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "kind": "Variable",
  "name": "zipCode",
  "variableName": "zipCode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "mobileNavWrapperRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MobileNavWrapper_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "mobileNavWrapperRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "isClient",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "relayIsClient",
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "if": null,
                "kind": "Defer",
                "label": "MobileNavWrapper_viewer$defer$userTracking_viewer",
                "selections": [
                  {
                    "condition": "hasUserId",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Variable",
                            "name": "buyerId",
                            "variableName": "userId"
                          }
                        ],
                        "concreteType": "TransactionsCounter",
                        "kind": "LinkedField",
                        "name": "transactionsCounter",
                        "plural": false,
                        "selections": [
                          {
                            "alias": "submittedTransactions",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "submitted",
                            "storageKey": null
                          },
                          {
                            "alias": "confirmedTransactions",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "confirmed",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "condition": "fetchTrackingGdprInfo",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "gdprDisplayInfo",
                        "kind": "LinkedField",
                        "name": "gdprDisplayInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": [
                              (v12/*: any*/)
                            ],
                            "kind": "ScalarField",
                            "name": "isGdprApplicable",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "alias": "isMultiItemCartFF",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "multiItemCart"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"multiItemCart\")"
          },
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  },
                  {
                    "kind": "Literal",
                    "name": "portfolioTypes",
                    "value": [
                      "CART"
                    ]
                  },
                  {
                    "kind": "Variable",
                    "name": "userIds",
                    "variableName": "userIds"
                  }
                ],
                "concreteType": "PortfoliosConnection",
                "kind": "LinkedField",
                "name": "portfolios",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfoliosEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PortfolioItemsConnection",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalResults",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  (v14/*: any*/)
                ],
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "if": null,
                    "kind": "Defer",
                    "label": "MobileNavSearchBar_viewer$defer$getRecentHistory_user",
                    "selections": [
                      {
                        "condition": "fetchUserRecentHistoryItem",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v15/*: any*/),
                            "concreteType": "RecentHistoryItemsConnection",
                            "kind": "LinkedField",
                            "name": "recentHistoryItems",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RecentHistoryItemsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Item",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "contemporaryTrackingString",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": [
                                          {
                                            "kind": "Literal",
                                            "name": "size",
                                            "value": "small"
                                          }
                                        ],
                                        "kind": "ScalarField",
                                        "name": "firstPhotoWebPath",
                                        "storageKey": "firstPhotoWebPath(size:\"small\")"
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "pdpURL",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "browseUrl",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "categoryCode",
                                        "storageKey": null
                                      },
                                      (v16/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "categoryPath",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Seller",
                                        "kind": "LinkedField",
                                        "name": "seller",
                                        "plural": false,
                                        "selections": [
                                          (v16/*: any*/),
                                          (v13/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v13/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfo",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v15/*: any*/),
                            "filters": [
                              "excludeItemPks"
                            ],
                            "handle": "connection",
                            "key": "getRecentHistory_recentHistoryItems",
                            "kind": "LinkedHandle",
                            "name": "recentHistoryItems"
                          }
                        ]
                      }
                    ]
                  },
                  (v13/*: any*/),
                  {
                    "condition": "isClient",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "if": null,
                        "kind": "Defer",
                        "label": "MobileNavWrapper_viewer$defer$userTracking_user",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdDate",
                            "storageKey": null
                          },
                          (v17/*: any*/),
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "verifiedTradeStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserExistsMetadataType",
                            "kind": "LinkedField",
                            "name": "authMetadata",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "emailOnly",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasPassword",
                                "storageKey": null
                              },
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Profile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "zipCode",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "emailMd5",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "emailSha256",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v21/*: any*/),
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalMessagesToDealers",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TradeFirmType",
                            "kind": "LinkedField",
                            "name": "tradeFirm",
                            "plural": false,
                            "selections": [
                              (v16/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "profileStatus",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TradeFirmRewards",
                                "kind": "LinkedField",
                                "name": "rewards",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ParentProgram",
                                    "kind": "LinkedField",
                                    "name": "currentProgram",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "LoyaltyProgram",
                                        "kind": "LinkedField",
                                        "name": "programInfo",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "code",
                                            "storageKey": null
                                          },
                                          (v13/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Promotion",
                            "kind": "LinkedField",
                            "name": "activePromotion",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "campaignName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "buyerDisplayable",
                                "storageKey": null
                              },
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "alias": "hasLoggedInWithApp",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "deviceType",
                        "value": "IOS_APP"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "hasLoggedInWithDeviceType",
                    "storageKey": "hasLoggedInWithDeviceType(deviceType:\"IOS_APP\")"
                  },
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Summary",
                    "kind": "LinkedField",
                    "name": "conversationSummary",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalUnreadConversationCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unreadActiveOffers",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EthereumAddressType",
                    "kind": "LinkedField",
                    "name": "ethereumWalletAddress",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "address",
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Profile",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": (v20/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AddressBook",
                    "kind": "LinkedField",
                    "name": "addressBook",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "personalAddress",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "country",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayCountry",
                            "storageKey": null
                          },
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v18/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "alias": "activeOfferNavFF",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "activeOfferNav"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"activeOfferNav\")"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currencies",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Geography",
            "kind": "LinkedField",
            "name": "geography",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GeographyObject",
                "kind": "LinkedField",
                "name": "countries",
                "plural": true,
                "selections": [
                  (v22/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "abbreviation",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "scandinavianCurrenciesEnabled",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "scandinavianCurrencies"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"scandinavianCurrencies\")"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "rootId",
                "variableName": "previewKey"
              },
              {
                "kind": "Variable",
                "name": "variantId",
                "variableName": "variantId"
              }
            ],
            "concreteType": "Navigation",
            "kind": "LinkedField",
            "name": "navigation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NavigationModule",
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  (v22/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NavigationTopLevel",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NavigationItem",
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": true,
                        "selections": [
                          (v16/*: any*/),
                          (v13/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/),
                      (v22/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v23/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "isMobileRecentSearchUiDisabled",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "disableMobileSearchRecentUi"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabled",
            "storageKey": "isEnabled(key:\"disableMobileSearchRecentUi\")"
          },
          {
            "alias": "isPopularSearchesEnabledByLocale",
            "args": [
              {
                "kind": "Literal",
                "name": "featureName",
                "value": "POPULAR_SEARCHES"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabledByLocale",
            "storageKey": "isEnabledByLocale(featureName:\"POPULAR_SEARCHES\")"
          },
          {
            "condition": "fetchRegionalInfo",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v12/*: any*/),
                  (v14/*: any*/),
                  (v24/*: any*/)
                ],
                "concreteType": "RegionalInfo",
                "kind": "LinkedField",
                "name": "regionalInfo",
                "plural": true,
                "selections": [
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      (v24/*: any*/)
                    ],
                    "concreteType": "IncomeLevel",
                    "kind": "LinkedField",
                    "name": "incomeLevel",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "incomeBracket",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "incomePercentile",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/505.0.0-2025-04-04T08:13:11.275Z/mobileNavWrapperRootQuery",
    "metadata": {},
    "name": "mobileNavWrapperRootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "369d252f56c96e7e0d52f344ff4eb821";

export default node;
